/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// any CSS you import will output into a single css file (app.css in this case)
import './styles/app.scss';

// require jQuery normally
const $ = require('jquery');

// create global $ and jQuery variables
global.$ = global.jQuery = $;

import 'datatables/media/js/jquery.dataTables.min.js';
import 'datatables/media/css/jquery.dataTables.min.css';
import 'ddslick/dist/jquery.ddslick.min.js';

$(function () {
  $( '.dataTable' ).dataTable( {
    paginate: true,
    pageLength: 100,
  });

  $( '.input-select.mod-image' ).ddslick({
    onSelected: function(selectedData){
        //callback function: do something with selectedData;
    }   
  });

  var setIframe = function() {
    if ($("#qr_code_redirectTo").val() == "") {
      return;
    }

    var ifrm = document.createElement("iframe");
    ifrm.style.transition = "1s";
    ifrm.style.opacity = "0";

    ifrm.setAttribute("src", "/bias/iframe?url=" + $("#qr_code_redirectTo").val());
    ifrm.style.width = "420px";
    ifrm.style.height = "420px";
    document.querySelector('.wrapper__iframe').innerHTML = '';
    document.querySelector('.wrapper__iframe').appendChild(ifrm);

    requestAnimationFrame(() =>
      setTimeout(() => {
        ifrm.style.opacity = "1";
      })
    );
  }

  $( '#qr_code_redirectTo' ).on('focusout', setIframe);

  if ( $( '#qr_code_redirectTo').length > 0 ) {
    setIframe();
  }

  /* $( '#downloadBeforeRedirect' ).on('click', function(e) {
    let url = window.location.href;    
    if (url.indexOf('?') > -1){
      url += '&download=1'
    } else {
      url += '?download=1'
    }

    window.location.search = url;
  }) */

// $('.wrapper__content-format-download').on('click', function(e) {
//   console.log($(this).data('name'));
//   console.log($(this).data('uri'));

//   e.preventDefault();
//   var link = document.createElement("a");
//   link.setAttribute('download', $(this).data('name'));
//   link.href = $(this).data('uri');
//   document.body.appendChild(link);
//   link.click();
//   link.remove();
// });

  if ( $( '#startDownload' ).length > 0 ) {

    fetch($( '#startDownload' ).data('url'), {
        method: 'GET'
      }).then( res => res.blob() )
        .then( blob => {
          var file = window.URL.createObjectURL(blob);
          var link = document.createElement('a');
          link.setAttribute('href', file);
          link.setAttribute('download', $( '#startDownload' ).data('name') + '.zip');
          link.click();
        });
  }

  var displayForm = function() {
    $( '.login' ).removeClass('mod-hide');
    $( '.header__logo' ).removeClass('mod-disconnected');
  }

  console.log(window.location.hash); 

  if (window.location.hash == '#connect') {
    displayForm();
  }

  $( '.login-button' ).on('click', displayForm);

});